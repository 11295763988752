*,
body,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

body {
  font-family: "Exo 2", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;    
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
  font-size: 16px; 
  background-color: #000;
  color: #fff; 
  }
  
  a h1 {
    color: #fff; 
    text-decoration: none;
  }

  main {
    padding: 0 40px; 
  }
  
  section {
    margin-bottom: 40px;
  }
  
  h2 {
    margin: 0; 
    text-align: left; 
    font-weight: 400; 

  }
  
  .projects, .colaborations {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
    margin-top: 30px;
    margin-left: 5px; 
    margin-right: 5px; 
  }
  
  .project, .colaboration {
    flex: 0 0 calc(33.33% - 10px); /* Ajusta el tamaño de las tarjetas */
    margin-bottom: 50px; /* Espacio entre las tarjetas */
  }
  
  .project a {
    text-decoration: none;
    color: inherit; 
    display: block; 
    background-color: #000; 
    padding: 0px; 
  }
  
  .project img {
    width: 100%; 
    height: auto; 

  }

  @media screen and (max-width: 768px) {
    .project, .colaboration {
      flex: 0 0 calc(50% - 10px); /* Ajusta el tamaño de las tarjetas para pantallas más pequeñas */
    }
  }
  
  @media screen and (max-width: 480px) {
    .project, .colaboration {
      flex: 0 0 calc(100% - 10px); /* Ajusta el tamaño de las tarjetas para pantallas aún más pequeñas */
      margin-left: 0; 
      margin-right: 0; 
    }
  }
  
  .project h3 {
    margin-top: 10px; 
  }
  
  .project p {
    margin-top: 5px; 
  }
  
  footer {
    background-color: #fff;
    color: #000;
    text-align: center;
    bottom: 10%;
    width: 100%;
  }
  
  nav {
    float: right;
    margin-top: 20px; 
    margin-right: 40px;
    margin-left: 20px;
  }
  
  nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  nav ul li {
    display: inline;
    margin-left: 10px; /* Espacio entre elementos de la lista */
  }
  
  nav ul li a {
    text-decoration: none;
    color: #fff;
  }
  
  #bio {
    background-color: #000;
    color: #fff;
    margin-top: 80px; 
    margin-bottom: 60px;

  }  
  
  header {
    display:relative;
    color: white;
    text-align: left; 
  }
  
  .header-content {
    display: flex; /* Usa flexbox para alinear elementos horizontalmente */
    align-items: center; 
  }
  
  h1 {
    display: inline-block; /* Para que el cuadro del título ocupe solo el espacio necesario */
    background-color: #333; /* Color de fondo del cuadro */
    padding: 10px 32px; /* Espacio alrededor del texto */
    margin-top: 20px; 
    margin-left: 40px;
    text-align: left;
    font-size: 16px; 
    font-weight: 400; /* Selección de una variante más negrita de la fuente */
    border-radius: 8px; /* Bordes redondeados */
    transition-duration: 0.4s; 
  }

  h3 {
    display: inline-block; /* Para que el cuadro del título ocupe solo el espacio necesario */
    color: #fff; /* Color de fondo del cuadro */
    margin-top: 20px; 
    text-align: left;
    font-size: 20px; 
    font-weight: 400;   
  }
 
  @font-face {
    font-family: 'Inter';
    src: url('../static/font/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  .contact-icon {
    font-size: 24px;
    margin-right: 10px;
    margin-top: 40px;
}

a {
  color: #fff; 
  text-decoration: underline; 
}

.container2 {
  column-count: 2;
  column-gap: 20px; 
}

.button {
  background-color: #4CAF50; 
  border: none; 
  color: white; 
  padding: 10px 20px; 
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px; 
  margin: 4px 2px; 
  cursor: pointer;
  border-radius: 8px; 
  transition-duration: 0.4s;
}

.button:hover {
  background-color: #45a049; 
}

@media screen and (max-width: 768px) {
  .container2 {
    column-count: 1; 
  }
}

@media screen and (min-width: 768px){
  .container2 {
    column-count: 3; 
  }
}

.project-details {
  text-align: left;
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 5%;

}

.project-details p {
  margin: 20px 0; 
}

@media screen and (max-width: 768px) {
  .project-details {
    margin-left: 1%;
    margin-right: 1%; 
  }
  h1 {
    display: inline-block; /* Para que el cuadro del título ocupe solo el espacio necesario */
    background-color: #333; /* Color de fondo del cuadro */
    padding: 10px 10px; /* Espacio alrededor del texto */
    margin-top: 20px; 
    margin-left: 40px;
    text-align: left;
    font-size: 16px; 
    font-weight: 400; /* Selección de una variante más negrita de la fuente */
    border-radius: 8px; /* Bordes redondeados */
    transition-duration: 0.4s; 
  }
}

.carousel {
  position: relative;
  width: 100%;
  max-width: 800px; 
  margin: 0 auto;
  overflow: hidden;
  height: 400px; 
  margin-top: 60px;
  margin-bottom: 60px; 
}

.carousel img {
  width: auto;
  max-width: 100%;
  height: auto; 
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  opacity: 0; 
  transition: opacity 1s ease-in-out; 
}

.carousel img.active {
  opacity: 1; /* Make the active image visible */
}

#backButton {
  font-family: "Exo 2", sans-serif;
  background-color: #333; 
  border: none; 
  color: white; 
  padding: 10px 32px; 
  text-decoration: none;
  display: block; 
  font-size: 16px; 
  cursor: pointer;
  border-radius: 4px; 
  transition-duration: 0.4s;
  margin: 60px auto 4px; 
  text-align: center; 
  width: 100%; 
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .carousel {
    height: 200px; /* Reduced height for smaller screens */
  }

  .carousel img {
    height: auto; /* Allow images to adjust height on smaller screens */
    max-height: 100%; /* Ensure images do not exceed the container height */
  }
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #000;
}

::-webkit-scrollbar-thumb {
  background-color: #333;
  border-radius: 10px;
  border: 3px solid #000;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #333 #000;
}

.video-container, .audio-container {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  margin-top: 20px;
}

.video-container iframe, .audio-container audio {
  max-width: 100%;
  margin-bottom: 10px; 
}

/* Estilo para el contenedor de audio */
.audio-player {
  max-width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}

/* Estilo para el elemento audio con Plyr */
.plyr {
  width: 40%;
  max-width: 400px;
  border-radius: 1px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
  margin-bottom: 50px;

}

/* Estilo para los controles del reproductor */
.plyr--audio .plyr__controls {
  background-color: #333 !important;
  color: #fff !important;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  padding: 0px;
  width: 100%;
  box-sizing: border-box; /* Incluye padding y border en el ancho total */
}

/* Estilo para los botones del reproductor */
.plyr--audio .plyr__controls button {
  color: #fff !important;
}

/* Estilo para el control de volumen */
.plyr--audio .plyr__volume {
  color: #fff !important;
}

/* Estilo para el rango de volumen */
.plyr--audio .plyr__volume input[type=range] {
  background-color: #333 !important;
}

/* Estilo para el control de progreso */
.plyr--audio .plyr__progress input[type=range] {
  background-color: #333 !important;
}

/* Estilo para el tiempo de reproducción */
.plyr--audio .plyr__time--current,
.plyr--audio .plyr__time--duration {
  color: #fff !important;
}
